<!-- 邀请列表 -->
<template>
    <div class="viewsPages">
        <div class="page-rebate-list">
            <!-- <div class="title-box">
                <div class="title" @click="_jump('rebate')">{{$t("userPage.text163")}}</div>
                <div class="subject">
                    <img src="@/assets/images/my/rightIcon.png" alt="">
                    {{$t("userPage.text188")}}
                </div>
            </div> -->
            <!-- 统计 -->
            <div class="rebate-total">
                <div>{{$t("userPage.text189")+"：￥"}}{{ totalBetValid }}</div>
                <div>{{$t("userPage.text190")+"：￥"}}{{ totalAllowance }}</div>
            </div>

            <!-- 注册时间 -->
            <div class="form-box">
                <div class="form-title">{{$t("userPage.text191")+"："}}</div>
                <div :class="{ 'form-item-active': form.dateType === key }" class="form-item" v-for="(val, key) in transDate" :key="key" @click="selectDate(key)">{{ val }}</div>
            </div>

            <!-- 选择时间 -->
            <div class="form-box">
                <div class="form-title">{{$t("userPage.text192")+"："}}</div>
                <div class="date-box">
                    <DatePicker :placeholder='$t("userPage.text5")' v-model="form.start" />
                    <span class="split-line">--</span>
                    <DatePicker :placeholder='$t("userPage.text5")' v-model="form.end" />
                </div>
                <div class="buttom-box">
                    <div class="query-buttom"  @click="query">{{$t("userPage.text6")}}</div>
                    <div class="reset-buttom" @click="reset">{{$t("userPage.text7")}}</div>
                </div>
            </div>
            <div class="content-box" v-if="rebateDataList.length > 0">
            <!-- 表格 -->
                <Table :data="rebateDataList">
                    <!-- 会员账号 -->
                    <TableColumn prop="memberName" :label='$t("userPage.text193")'></TableColumn>
                    <!-- 注册时间 -->
                    <TableColumn prop="registerDate" :label='$t("userPage.text194")'></TableColumn>
                    <!-- 总有效投注(元) -->
                    <TableColumn prop="validAmount" :label='$t("userPage.text195")'></TableColumn>
                    <!-- 返利金(元) -->
                    <TableColumn prop="allowance" :label='$t("userPage.text196")'></TableColumn>
                </Table>
                <!-- 分页 -->
                <div class="pagination-boxs">
                    <Pagination
                    layout="prev, pager, next"
                    :hide-on-single-page="true"
                    :current-page.sync="form.currPage"
                    :page-size="form.pageSize"
                    :total="rebateDataTotal"
                    @size-change="getData"
                    @current-change="getData"
                    ></Pagination>
                </div>
            </div>
            <div v-else class="null-box">
                <img loading="lazy" v-lazy="require('../../../assets/images/my/null.png')">
                <div>{{$t("userPage.text12")}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { _jump } from '@/core/utils/utils'
import { DatePicker, Table, TableColumn, Pagination } from 'element-ui'
import { _memberAllowanceRecord } from '../../../core/api/modules/records'
import { mapGetters } from 'vuex'

// 注册时间
const transDate = {
    0: '今日',
    1: '昨日',
    7: '近7日',
    30: '近30日'
}

export default {
    components: {
        DatePicker,
        Table,
        TableColumn,
        Pagination,
    },
    data () {
        return {
            transDate,
            form: {
                currPage: 1,
                pageSize: 10,
                dateType: '',
                start: '',
                end: '',
            }
        }
    },
    computed: {
        ...mapGetters([
            'rebateDataList', // 当前页数据
            'rebateDataTotal', // 总条数
            'totalBetValid', // 总投注
            'totalAllowance', // 累计获得总返利
        ]),
    },
    methods: {
        _jump,
        // 查询
        query () {
            this.form.currPage = 1
            this.getData()
        },
        // 获取数据
        getData () {
            setTimeout(() => {
                _memberAllowanceRecord(this.form)
            }, 0)
        },
        // 重置
        reset () {
            this.form = {
                dateType: '',
                start: '',
                end: '',
            }
        },
        // 选择日期
        selectDate (val) {
            this.form.dateType = val

            // 设置起止时间
            const now = new Date()
            const toDay = new Date(`${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()} 00:00:00`)
            console.log(toDay)
            const end = toDay.getTime() - 1000
            const start = end - (24 * 60 * 60 * 1000 * val) + 1000
            this.form.start = new Date(start)
            this.form.end = new Date(end)
            if (Number(val) === 0) { // 今日
                this.form.start = toDay
                this.form.end = new Date(toDay.getTime() - 1000 + 24 * 60 * 60 * 1000)
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/page-rebate-list.scss";
</style>
<style scoped>
.form-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.form-title {
    width: 100px;
}
.form-item {
    cursor: pointer;
    margin-right: 10px;
}
.form-item-active {
    background-color: red;
    color: #fff;
}
</style>